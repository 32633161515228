import React from 'react';
import { CreditCard, Shield, Star, Zap, Award, Fingerprint } from 'lucide-react';

// Add NavLink component
const NavLink = ({ href, text }) => (
    <a
        href={href}
        className="text-white/70 hover:text-white transition-colors duration-300"
    >
        {text}
    </a>
);

const cardModels = [
    {
        name: "Nexus Infinity",
        type: "Premium Kreditkarte",
        benefits: [
            "Exklusive Zugangslounges weltweit",
            "Keine Auslandseinsatzgebühr",
            "Premium-Versicherungen für Reisen und Einkäufe",
            "Höchste Sicherheitsstandards"
        ],
        icon: <CreditCard className="h-10 w-10 text-white" />,
        gradient: "from-purple-500 to-pink-500"
    },
    {
        name: "Nexus Velocity",
        type: "Debitkarte",
        benefits: [
            "Direkte Transaktionsabwicklung",
            "Kostenlose Abhebungen weltweit",
            "Sicherer Überblick durch Echtzeit-Kontrolle",
            "Sofortige Sperr- und Limiteinstellungen"
        ],
        icon: <CreditCard className="h-10 w-10 text-white" />,
        gradient: "from-blue-500 to-purple-500"
    },
    {
        name: "Nexus Mirage",
        type: "Virtuelle Karte",
        benefits: [
            "Einmalige Kartennummern für Online-Zahlungen",
            "Maximale Sicherheit durch Limitierung",
            "Perfekt für digitale Wallets und Abos",
            "Automatische Löschung nach Nutzung"
        ],
        icon: <Shield className="h-10 w-10 text-white" />,
        gradient: "from-pink-500 to-purple-500"
    }
];

const CardModelsPage = () => {
    return (
        <div className="min-h-screen bg-black text-white">
            {/* Futuristische Navigation */}
            <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between h-20">
                        <a href="/" className="flex items-center hover:opacity-80 transition-opacity duration-300">
                            <Zap className="h-8 w-8 text-purple-500" strokeWidth={2.5} />
                            <span className="ml-2 text-2xl font-bold tracking-tighter">Nexus Bank</span>
                        </a>

                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink href="/aboutus" text="Über uns"/>
                            <NavLink href="/services" text="Unsere Dienstleistungen"/>
                            <NavLink href="/card" text="Kreditkarten und Debitkarten Modelle" />
                            <NavLink href="/contact" text="Kontakt"/>
                            <a
                                href="/portfolio"
                                className="group relative px-6 py-2 overflow-hidden rounded-full bg-white/10 hover:bg-white/20 transition-all duration-300"
                            >
                                <span className="relative z-10">Open Portal</span>
                                <div className="absolute inset-0 blur-lg bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden pt-20">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>

                {/* Animated background elements */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10 mb-6">
                        <Award className="h-4 w-4 text-purple-400" />
                        <span className="text-sm text-purple-200">Premium Zahlungslösungen</span>
                    </div>

                    <h1 className="text-5xl lg:text-6xl font-bold tracking-tighter leading-tight mb-6">
                        Unsere
                        <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            {" "}Kartenmodelle
                        </span>
                    </h1>

                    <p className="text-xl text-white/60 max-w-3xl mx-auto">
                        Entdecken Sie unsere exklusiven Kredit- und Debitkarten sowie die
                        revolutionäre Zahlungsmethode Nexus QuantumID.
                    </p>
                </div>
            </div>

            {/* Kartenmodelle */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        {cardModels.map((card, index) => (
                            <div key={index} className="group relative">
                                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
                                <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                                    <div className="flex items-center space-x-4 mb-6">
                                        <div className={`w-12 h-12 rounded-full bg-gradient-to-r ${card.gradient} flex items-center justify-center`}>
                                            {card.icon}
                                        </div>
                                        <div>
                                            <h2 className="text-xl font-semibold">{card.name}</h2>
                                            <span className="text-white/60">{card.type}</span>
                                        </div>
                                    </div>
                                    <ul className="space-y-3">
                                        {card.benefits.map((benefit, idx) => (
                                            <li key={idx} className="flex items-center space-x-2">
                                                <div className="w-1.5 h-1.5 rounded-full bg-gradient-to-r from-purple-400 to-pink-400"></div>
                                                <span className="text-white/80">{benefit}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Die nächste Generation: Nexus QuantumID */}
                    <div className="relative mt-20">
                        <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-25"></div>
                        <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10">
                            <div className="flex items-center space-x-4 mb-6">
                                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center">
                                    <Fingerprint className="h-6 w-6 text-white" />
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold">Die nächste Generation: Nexus QuantumID</h2>
                                    <p className="text-white/60">Biometrische Authentifizierung der Zukunft</p>
                                </div>
                            </div>
                            <p className="text-white/80 mb-8">
                                Der Nexus QuantumID ist eine revolutionäre Technologie, die Ihre Identität auf Basis
                                neuronaler und biometrischer Signaturen erkennt. Ganz ohne Karte oder Handy erlaubt
                                QuantumID eine sichere und kontaktlose Zahlungserfahrung.
                            </p>
                            <div className="grid md:grid-cols-2 gap-8">
                                <div className="space-y-4">
                                    <h3 className="font-semibold text-lg mb-4">Einzigartige Vorteile</h3>
                                    <ul className="space-y-3">
                                        {[
                                            "Neurale und biometrische Verifizierung",
                                            "Kein Gerät erforderlich – nur Ihre Anwesenheit",
                                            "Höchster Schutz durch KI-gestützte Verifizierung",
                                            "Überall, wo QuantumID unterstützt wird"
                                        ].map((item, idx) => (
                                            <li key={idx} className="flex items-center space-x-2">
                                                <div className="w-1.5 h-1.5 rounded-full bg-gradient-to-r from-purple-400 to-pink-400"></div>
                                                <span className="text-white/80">{item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="space-y-4">
                                    <h3 className="font-semibold text-lg mb-4">Wie es funktioniert</h3>
                                    <ul className="space-y-3">
                                        {[
                                            "Einrichtung im Nexus Bankcenter",
                                            "Gesichtserkennung und neuronale Musteranalyse",
                                            "Automatische Identifizierung an unterstützten Terminals",
                                            "Kein PIN, keine Unterschrift – einfach und sicher"
                                        ].map((item, idx) => (
                                            <li key={idx} className="flex items-center space-x-2">
                                                <div className="w-1.5 h-1.5 rounded-full bg-gradient-to-r from-purple-400 to-pink-400"></div>
                                                <span className="text-white/80">{item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CTA Section */}
                    <div className="relative py-20">
                        <div className="max-w-3xl mx-auto text-center">
                            <h2 className="text-3xl font-bold mb-6">
                                Bereit für die Zukunft des Bezahlens?
                            </h2>
                            <p className="text-white/60 mb-8">
                                Werden Sie Teil der Payment-Revolution und erleben Sie maximale Sicherheit kombiniert mit höchstem Komfort.
                            </p>
                            <a
                                href="/pay"
                                className="group relative px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden inline-block"
                            >
                                <span className="relative z-10 font-medium">Jetzt QuantumPay aktivieren</span>
                                <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardModelsPage;
