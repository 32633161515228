import React from 'react';
import {
    Briefcase,
    Award,
    Users,
    Globe,
    TrendingUp,
    Zap
} from 'lucide-react';

// NavLink Component
const NavLink = ({ href, text }) => (
    <a
        href={href}
        className="text-white/70 hover:text-white transition-colors duration-300"
    >
        {text}
    </a>
);

const TeamPage = () => {
    return (
        <div className="min-h-screen bg-black text-white">
            {/* Navigation */}
            <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between h-20">
                        <a href="/" className="flex items-center hover:opacity-80 transition-opacity duration-300">
                            <Zap className="h-8 w-8 text-purple-500" strokeWidth={2.5}/>
                            <span className="ml-2 text-2xl font-bold tracking-tighter">Nexus Bank</span>
                        </a>

                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink href="/aboutus" text="Über uns"/>
                            <NavLink href="/services" text="Unsere Dienstleistungen"/>
                            <NavLink href="/card" text="Kreditkarten und Debitkarten Modelle"/>
                            <NavLink href="/contact" text="Kontakt"/>
                            <a
                                href="/portfolio"
                                className="group relative px-6 py-2 overflow-hidden rounded-full bg-white/10 hover:bg-white/20 transition-all duration-300"
                            >
                                <span className="relative z-10">Open Portal</span>
                                <div className="absolute inset-0 blur-lg bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden pt-20">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>

                {/* Animated background elements */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10 mb-6">
                        <Users className="h-4 w-4 text-purple-400" />
                        <span className="text-sm text-purple-200">Experten für Ihren Erfolg</span>
                    </div>

                    <h1 className="text-5xl lg:text-6xl font-bold tracking-tighter leading-tight mb-6">
                        Das Team der
                        <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            {" "}Nexus Bank
                        </span>
                    </h1>

                    <p className="text-xl text-white/60 max-w-3xl mx-auto">
                        Hinter der Nexus Bank steht ein engagiertes Team aus erfahrenen Finanzexperten, Investitionsstrategen und Branchenführern. Unser Team setzt sich für Ihren Erfolg ein und bietet Ihnen die besten Lösungen in einer dynamischen Finanzwelt.
                    </p>
                </div>
            </div>

            {/* Team Members Section */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <TeamMember
                            name="Dr. Michael Stein"
                            position="CEO & Gründer"
                            description="Mit über 20 Jahren Erfahrung im Finanzsektor und einer Leidenschaft für innovative Lösungen leitet Dr. Stein die Nexus Bank mit visionärem Denken und strategischem Geschick."
                            icon={<Briefcase className="h-6 w-6 text-white" />}
                        />
                        <TeamMember
                            name="Sophie Wagner"
                            position="Leiterin Investitionsstrategien"
                            description="Sophie ist Expertin für globale Märkte und führt das Investment-Team mit der Vision, die besten Investitionsmöglichkeiten für unsere exklusiven Kunden zu identifizieren."
                            icon={<TrendingUp className="h-6 w-6 text-white" />}
                        />
                        <TeamMember
                            name="Laura Beck"
                            position="Head of International Relations"
                            description="Mit einem globalen Netzwerk und einem feinen Gespür für internationale Märkte unterstützt Laura unsere Kunden bei internationalen Investmentvorhaben."
                            icon={<Globe className="h-6 w-6 text-white" />}
                        />
                        <TeamMember
                            name="Jonas Meier"
                            position="Finanzanalyst"
                            description="Jonas liefert wertvolle Analysen und Einblicke in Markttrends und unterstützt das Team bei der Entwicklung profitabler Anlagestrategien."
                            icon={<Award className="h-6 w-6 text-white" />}
                        />
                        <TeamMember
                            name="Emma Schneider"
                            position="Kundenbetreuung & Beratung"
                            description="Emma ist Ansprechpartnerin für unsere Kunden und bietet eine persönliche, erstklassige Betreuung – für maßgeschneiderte Lösungen und ein einmaliges Bankerlebnis."
                            icon={<Users className="h-6 w-6 text-white" />}
                        />
                        <TeamMember
                            name="David König"
                            position="Chief Technology Officer (CTO)"
                            description="David entwickelt modernste Technologien und Sicherheitslösungen für die Nexus Bank, um das digitale Erlebnis unserer Kunden stetig zu verbessern."
                            icon={<Briefcase className="h-6 w-6 text-white" />}
                        />
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="relative py-20">
                <div className="max-w-3xl mx-auto text-center px-4">
                    <h2 className="text-3xl font-bold mb-6">
                        Lernen Sie uns kennen und erfahren Sie mehr über unsere Expertise!
                    </h2>
                    <p className="text-white/60 mb-8">
                        Wir sind stolz auf unser Team und freuen uns darauf, Sie auf Ihrem Weg zum finanziellen Erfolg zu begleiten.
                    </p>
                    <button className="group relative px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden">
                        <span className="relative z-10 font-medium">Kontakt aufnehmen</span>
                        <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Team Member Card mit gleichem Design wie ValueCard
const TeamMember = ({ name, position, description, icon }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="flex items-center space-x-4 mb-6">
                    <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center">
                        {icon}
                    </div>
                    <div>
                        <h3 className="text-xl font-medium">{name}</h3>
                        <span className="text-white/60">{position}</span>
                    </div>
                </div>
                <p className="text-white/60">{description}</p>
            </div>
        </div>
    );
};

export default TeamPage;