import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NexusBankWebsite from "../src/components/home";
import AboutUs from "./components/aboutus";
import Services from "./components/services";
import Contact from "./components/contact"
import DemoPortal from "./components/portfolio";
import PaymentSolutions from "./components/pay"
import CardModelsPage from "./components/card"
import AboutNexusBank from "./components/bank"
import LearnMoreNexusBank from "./components/more"
import RegistrationPortal from "./components/login"
import DigitalAccountPage from "./components/account"
import InvestmentsPage from "./components/investment"
import RealEstateFinancingPage from "./components/finance"
import TeamPage from "./components/team"
import CareersPage from "./components/career";
import PrivacyPolicyPage from "./components/privacy";
import TermsAndConditionsPage from "./components/agb";
import ImpressumPage from "./components/impressum";



const App = () => {
  return (
      <Router>
        <Routes>
            <Route path="/" element={<NexusBankWebsite />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path={"/services"} element={<Services />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"/portfolio"} element={<DemoPortal />} />
            <Route path={"/pay"} element={<PaymentSolutions />} />
            <Route path={"/card"} element={<CardModelsPage />} />
            <Route path={"/bank"} element={<AboutNexusBank />} />
            <Route path={"/more"} element={<LearnMoreNexusBank />} />
            <Route path={"/login"} element={<RegistrationPortal />} />
            <Route path={"/account"} element={<DigitalAccountPage />} />
            <Route path={"/investment"} element={<InvestmentsPage />} />
            <Route path={"/finance"} element={<RealEstateFinancingPage />} />
            <Route path={"/team"} element={<TeamPage />} />
            <Route path={"/career"} element={<CareersPage />} />
            <Route path={"/privacy"} element={<PrivacyPolicyPage />} />
            <Route path={"/agb"} element={<TermsAndConditionsPage />} />
            <Route path={"/impressum"} element={<ImpressumPage />} />
        </Routes>
      </Router>
  );
};

export default App;
