import React from 'react';

const ImpressumPage = () => {
    return (
        <div className="min-h-screen bg-black text-white px-6 lg:px-16 py-20">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8">Impressum</h1>
                <p className="text-white/60 mb-12">
                    Dieses Impressum enthält die gesetzlich vorgeschriebenen Informationen zur Anbieterkennzeichnung der Nexus Bank.
                </p>

                <Section
                    title="Angaben gemäß § 5 TMG"
                    content={
                        <>
                            <strong>Nexus Bank AG</strong>
                            <br />
                            Hauptsitz: Friedrichstraße 123, 10117 Berlin, Deutschland
                            <br />
                            Handelsregister: HRB 123456, Amtsgericht Berlin
                            <br />
                            USt-IdNr.: DE123456789
                        </>
                    }
                />

                <Section
                    title="Vertreten durch"
                    content="Dr. Michael Stein (Vorstandsvorsitzender), Sophie Wagner (Vorstand)"
                />

                <Section
                    title="Kontakt"
                    content={
                        <>
                            Telefon: +49 (0)30 123456-0
                            <br />
                            Fax: +49 (0)30 123456-99
                            <br />
                            E-Mail: <a href="mailto:info@nexusbank.com" className="text-purple-400">info@nexusbank.com</a>
                        </>
                    }
                />

                <Section
                    title="Aufsichtsbehörde"
                    content={
                        <>
                            Die Nexus Bank AG wird beaufsichtigt durch die Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin).
                            <br />
                            <strong>BaFin</strong>
                            <br />
                            Graurheindorfer Straße 108, 53117 Bonn, Deutschland
                            <br />
                            Website: <a href="https://www.bafin.de" target="_blank" rel="noopener noreferrer" className="text-purple-400">www.bafin.de</a>
                        </>
                    }
                />

                <Section
                    title="Haftungsausschluss (Disclaimer)"
                    content={
                        <>
                            Die Nexus Bank übernimmt keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte auf dieser Website. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers.
                            <br />
                            Als Dienstanbieter gemäß § 7 Abs.1 TMG ist die Nexus Bank für eigene Inhalte auf dieser Website nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                        </>
                    }
                />

                <Section
                    title="Urheberrecht"
                    content="Die durch die Nexus Bank erstellten Inhalte und Werke auf dieser Website unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung der Nexus Bank oder des jeweiligen Rechteinhabers."
                />

                <Section
                    title="Online-Streitbeilegung"
                    content={
                        <>
                            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer" className="text-purple-400">https://ec.europa.eu/consumers/odr</a>.
                            <br />
                            Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind weder verpflichtet noch bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                        </>
                    }
                />
            </div>
        </div>
    );
};

const Section = ({ title, content }) => {
    return (
        <div className="mb-10">
            <h2 className="text-2xl font-semibold mb-2">{title}</h2>
            <p className="text-white/80">{content}</p>
        </div>
    );
};

export default ImpressumPage;
