import React from 'react';
import {
    Home,
    Shield,
    Globe,
    TrendingUp,
    DollarSign,
    Calendar,
    Zap,
    Award
} from 'lucide-react';

// NavLink Component
const NavLink = ({ href, text }) => (
    <a
        href={href}
        className="text-white/70 hover:text-white transition-colors duration-300"
    >
        {text}
    </a>
);

const RealEstateFinancingPage = () => {
    return (
        <div className="min-h-screen bg-black text-white">
            {/* Navigation */}
            <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between h-20">
                        <a href="/" className="flex items-center hover:opacity-80 transition-opacity duration-300">
                            <Zap className="h-8 w-8 text-purple-500" strokeWidth={2.5}/>
                            <span className="ml-2 text-2xl font-bold tracking-tighter">Nexus Bank</span>
                        </a>

                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink href="/aboutus" text="Über uns"/>
                            <NavLink href="/services" text="Unsere Dienstleistungen"/>
                            <NavLink href="/card" text="Kreditkarten und Debitkarten Modelle"/>
                            <NavLink href="/contact" text="Kontakt"/>
                            <a
                                href="/portfolio"
                                className="group relative px-6 py-2 overflow-hidden rounded-full bg-white/10 hover:bg-white/20 transition-all duration-300"
                            >
                                <span className="relative z-10">Open Portal</span>
                                <div className="absolute inset-0 blur-lg bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden pt-20">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>

                {/* Animated background elements */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10 mb-6">
                        <Award className="h-4 w-4 text-purple-400" />
                        <span className="text-sm text-purple-200">Premium Immobilienfinanzierung</span>
                    </div>

                    <h1 className="text-5xl lg:text-6xl font-bold tracking-tighter leading-tight mb-6">
                        Immobilienfinanzierung
                        <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            {" "}der Nexus Bank
                        </span>
                    </h1>

                    <p className="text-xl text-white/60 max-w-3xl mx-auto">
                        Mit der Immobilienfinanzierung der Nexus Bank eröffnen sich Ihnen neue Möglichkeiten, in Ihr Traumobjekt oder strategische Immobilienprojekte zu investieren. Unsere maßgeschneiderten Finanzierungslösungen und unser Team aus erfahrenen Beratern begleiten Sie von der Planung bis zur Umsetzung.
                    </p>
                </div>
            </div>

            {/* Features Section */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <FeatureCard
                            icon={<Home className="h-6 w-6 text-white" />}
                            title="Individuelle Finanzierungslösungen"
                            description="Jede Immobilie ist einzigartig – genau wie unsere Finanzierungslösungen. Wir passen unsere Angebote auf Ihre persönlichen Ziele und die Eigenschaften Ihrer Immobilie an."
                        />
                        <FeatureCard
                            icon={<Shield className="h-6 w-6 text-white" />}
                            title="Sicherheit & Stabilität"
                            description="Unsere Immobilienfinanzierungen bieten langfristige Stabilität und sind auf Sicherheit ausgelegt, damit Sie Ihre Investition mit einem guten Gefühl realisieren können."
                        />
                        <FeatureCard
                            icon={<Globe className="h-6 w-6 text-white" />}
                            title="Internationale Immobilien"
                            description="Unser globales Netzwerk ermöglicht es Ihnen, auch in internationale Immobilienprojekte zu investieren und Ihre Möglichkeiten zu erweitern."
                        />
                        <FeatureCard
                            icon={<TrendingUp className="h-6 w-6 text-white" />}
                            title="Wertsteigerung und Investitionsstrategien"
                            description="Mit strategischer Beratung und Marktanalysen helfen wir Ihnen, in Immobilien zu investieren, die ein hohes Potenzial für langfristige Wertsteigerung bieten."
                        />
                        <FeatureCard
                            icon={<DollarSign className="h-6 w-6 text-white" />}
                            title="Flexible Finanzierungsmodelle"
                            description="Unsere flexiblen Finanzierungsmodelle umfassen verschiedene Darlehensoptionen und maßgeschneiderte Rückzahlungspläne, die auf Ihre Bedürfnisse abgestimmt sind."
                        />
                        <FeatureCard
                            icon={<Calendar className="h-6 w-6 text-white" />}
                            title="Langfristige Planung"
                            description="Gemeinsam mit Ihnen entwickeln wir einen Finanzierungsplan, der langfristige Stabilität gewährleistet und Sie bei jedem Schritt unterstützt – heute und in Zukunft."
                        />
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="relative py-20">
                <div className="max-w-3xl mx-auto text-center px-4">
                    <h2 className="text-3xl font-bold mb-6">
                        Bereit, in Ihre Traumimmobilie zu investieren?
                    </h2>
                    <p className="text-white/60 mb-8">
                        Kontaktieren Sie uns für eine persönliche Beratung und entdecken Sie die exklusiven Immobilienfinanzierungsmöglichkeiten der Nexus Bank.
                    </p>
                    <button className="group relative px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden">
                        <span className="relative z-10 font-medium">Jetzt Beratung vereinbaren</span>
                        <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Feature Card mit gleichem Design wie ValueCard
const FeatureCard = ({ icon, title, description }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mb-6">
                    {icon}
                </div>
                <h3 className="text-xl font-medium mb-2">{title}</h3>
                <p className="text-white/60">{description}</p>
            </div>
        </div>
    );
};

export default RealEstateFinancingPage;