import React from 'react';

const TermsAndConditionsPage = () => {
    return (
        <div className="min-h-screen bg-black text-white px-6 lg:px-16 py-20">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8">Allgemeine Geschäftsbedingungen (AGB)</h1>
                <p className="text-white/60 mb-12">
                    Die folgenden Allgemeinen Geschäftsbedingungen (AGB) regeln die Nutzung der Dienste und Produkte der Nexus Bank. Bitte lesen Sie diese sorgfältig durch, bevor Sie unsere Dienste in Anspruch nehmen.
                </p>

                <Section
                    title="1. Geltungsbereich"
                    content="Diese AGB gelten für alle Verträge und Dienstleistungen, die zwischen der Nexus Bank und ihren Kunden abgeschlossen werden. Durch die Nutzung unserer Dienste akzeptieren Sie die hier aufgeführten Bedingungen."
                />

                <Section
                    title="2. Vertragsabschluss"
                    content="Ein Vertrag zwischen der Nexus Bank und dem Kunden kommt zustande, sobald der Kunde ein Konto eröffnet oder eine Dienstleistung der Nexus Bank in Anspruch nimmt. Die Nexus Bank behält sich das Recht vor, Anträge auf Kontoeröffnung oder andere Dienstleistungen ohne Angabe von Gründen abzulehnen."
                />

                <Section
                    title="3. Kontoführung und Sicherheit"
                    content={
                        <>
                            Der Kunde ist verpflichtet, seine Zugangsdaten und Passwörter sicher aufzubewahren und Dritten nicht zugänglich zu machen. Die Nexus Bank übernimmt keine Haftung für Schäden, die durch unbefugte Nutzung des Kundenkontos entstehen, sofern diese nicht durch grobe Fahrlässigkeit der Nexus Bank verursacht wurden.
                            <br />
                            Die Nutzung der Dienste der Nexus Bank erfolgt gemäß den Sicherheitsrichtlinien, die unter den geltenden Datenschutzgesetzen, insbesondere der DSGVO, und den Sicherheitsstandards in <strong>Art. 32 DSGVO</strong> festgelegt sind.
                        </>
                    }
                />

                <Section
                    title="4. Zahlungsbedingungen"
                    content="Die Nexus Bank erhebt für ihre Dienstleistungen Gebühren gemäß der jeweils gültigen Preisliste. Gebühren und Kosten werden dem Kundenkonto belastet und sind sofort fällig. Der Kunde wird über Gebührenanpassungen rechtzeitig informiert."
                />

                <Section
                    title="5. Haftung"
                    content="Die Nexus Bank haftet nur für Schäden, die auf vorsätzlichem oder grob fahrlässigem Verhalten beruhen. Für leichte Fahrlässigkeit haftet die Nexus Bank nur bei Verletzung wesentlicher Vertragspflichten. Eine Haftung für mittelbare Schäden oder entgangenen Gewinn ist ausgeschlossen, soweit gesetzlich zulässig."
                />

                <Section
                    title="6. Widerrufsrecht"
                    content="Kunden, die Verbraucher im Sinne des BGB sind, haben das Recht, den Vertrag innerhalb von 14 Tagen ohne Angabe von Gründen zu widerrufen. Um das Widerrufsrecht auszuüben, muss der Kunde eine eindeutige Erklärung an die Nexus Bank senden."
                />

                <Section
                    title="7. Kündigung"
                    content="Der Vertrag kann von beiden Parteien unter Einhaltung einer Kündigungsfrist von 30 Tagen schriftlich gekündigt werden. Die Nexus Bank behält sich das Recht vor, den Vertrag aus wichtigem Grund ohne Einhaltung einer Frist zu kündigen."
                />

                <Section
                    title="8. Datenschutz"
                    content={
                        <>
                            Der Schutz der personenbezogenen Daten des Kunden ist der Nexus Bank ein besonderes Anliegen. Alle Daten werden gemäß der geltenden Datenschutzgesetze, insbesondere der DSGVO, verarbeitet. Weitere Informationen finden Sie in unserer <a href="/datenschutz" className="text-purple-400">Datenschutzerklärung</a>.
                        </>
                    }
                />

                <Section
                    title="9. Änderungen der AGB"
                    content="Die Nexus Bank behält sich das Recht vor, diese AGB jederzeit zu ändern. Änderungen werden dem Kunden rechtzeitig mitgeteilt. Widerspricht der Kunde den Änderungen nicht innerhalb von sechs Wochen nach Mitteilung, gelten die Änderungen als akzeptiert."
                />

                <Section
                    title="10. Anwendbares Recht und Gerichtsstand"
                    content="Für alle Streitigkeiten aus oder im Zusammenhang mit diesen AGB gilt das Recht der Bundesrepublik Deutschland. Gerichtsstand für alle Streitigkeiten ist, soweit gesetzlich zulässig, der Sitz der Nexus Bank."
                />

                <div className="mt-16">
                    <h2 className="text-xl font-bold mb-4">Kontakt</h2>
                    <p className="text-white/80">
                        Bei Fragen zu unseren AGB wenden Sie sich bitte an unseren Kundenservice:
                        <br />
                        <span className="font-semibold">Email:</span> <a href="mailto:support@nexusbank.com" className="text-purple-400">support@nexusbank.com</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, content }) => {
    return (
        <div className="mb-10">
            <h2 className="text-2xl font-semibold mb-2">{title}</h2>
            <p className="text-white/80">{content}</p>
        </div>
    );
};

export default TermsAndConditionsPage;
