import React, { useState } from 'react';
import {
    Zap,
    Lock,
    Wallet,
    Menu,
    X,
    Sparkles,
    CircleDollarSign,
    Users
} from 'lucide-react';

const NexusBankWebsite = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="min-h-screen bg-black text-white">
            {/* Futuristische Navigation */}
            <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between h-20">
                        <a href="/" className="flex items-center hover:opacity-80 transition-opacity duration-300">
                            <Zap className="h-8 w-8 text-purple-500" strokeWidth={2.5}/>
                            <span className="ml-2 text-2xl font-bold tracking-tighter">Nexus Bank</span>
                        </a>
                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink href="/aboutus" text="Über uns"/>
                            <NavLink href="/services" text="Unsere Dienstleistungen"/>
                            <NavLink href="/card" text="Kreditkarten und Debitkarten Modelle"/>
                            <NavLink href="/contact" text="Kontakt"/>
                            <a
                                href="/portfolio"  // Ersetze "/portal" durch den tatsächlichen Link
                                className="group relative px-6 py-2 overflow-hidden rounded-full bg-white/10 hover:bg-white/20 transition-all duration-300"
                            >
                                <span className="relative z-10">Open Portal</span>
                                <div
                                    className="absolute inset-0 blur-lg bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            </a>

                        </div>

                        <button
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            className="md:hidden text-white p-2"
                        >
                            {isMenuOpen ? <X size={24}/> : <Menu size={24}/>}
                        </button>
                    </div>
                </div>
            </nav>

            {/* Hero Section mit dynamischen Elementen */}
            <div className="relative min-h-screen flex items-center justify-center overflow-hidden pt-20">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>

                {/* Hintergrundanimation */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4 py-32">
                    <div className="grid lg:grid-cols-2 gap-16 items-center">
                        <div className="space-y-8">
                            <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10">
                                <Sparkles className="h-4 w-4 text-purple-400" />
                                <span className="text-sm text-purple-200">Die Zukunft des Bankwesens</span>
                            </div>

                            <h1 className="text-6xl lg:text-7xl font-bold tracking-tighter leading-tight">
                                Banking
                                <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                  {" "}neu{" "}
                </span>
                                erfunden
                            </h1>

                            <p className="text-xl text-white/60 leading-relaxed">
                                Nexus Bank vereint Innovation mit Sicherheit. Als führende Bank der Zukunft bieten wir maßgeschneiderte Lösungen für alle Ihre finanziellen Bedürfnisse.
                            </p>

                            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                                <a
                                    href="/bank"
                                    className="group relative inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden"
                                >
                                    <span className="relative z-10 font-medium">Entdecken Sie Nexus Bank</span>
                                    <div
                                        className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                </a>

                                <a
                                    href="/more"
                                    className="group inline-flex items-center justify-center px-8 py-4 rounded-full border border-white/10 hover:border-white/20 transition-colors duration-300"
                                >
                                    <span className="font-medium">Erfahren Sie mehr</span>
                                </a>
                            </div>
                        </div>

                        {/* Futuristisches Interface-Element */}
                        <div className="relative">
                            <div
                                className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-50"></div>
                            <div
                                className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10">
                                <div className="space-y-8">
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                        <div className="bg-white/5 p-6 rounded-2xl text-center">
                                            <div
                                                className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mx-auto mb-4">
                                                <Users className="h-6 w-6 text-white" />
                                            </div>
                                            <div className="text-3xl font-bold mb-2">7.500</div>
                                            <div className="text-sm text-white/60">Zufriedene Kunden</div>
                                        </div>

                                        <div className="bg-white/5 p-6 rounded-2xl text-center">
                                            <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mx-auto mb-4">
                                                <CircleDollarSign className="h-6 w-6 text-white" />
                                            </div>
                                            <div className="text-3xl font-bold mb-2">250 Mrd.</div>
                                            <div className="text-sm text-white/60">Verwaltetes Vermögen</div>
                                        </div>

                                        <div className="bg-white/5 p-6 rounded-2xl text-center">
                                            <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mx-auto mb-4">
                                                <Lock className="h-6 w-6 text-white" />
                                            </div>
                                            <div className="text-3xl font-bold mb-2">AAA</div>
                                            <div className="text-sm text-white/60">Fitch Rating</div>
                                        </div>
                                    </div>

                                    <div className="bg-white/5 p-6 rounded-2xl text-center">
                                        <p className="text-lg mb-4">
                                            "Die vertrauenswürdigste Bank für die digitale Ära."
                                        </p>
                                        <p className="text-white/60">
                                            - Nexus Financial, 2024
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Features Grid - Neu interpretiert */}
            <div className="relative py-32 overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-b from-black to-purple-900/20"></div>

                <div className="relative max-w-7xl mx-auto px-4">
                    <div className="text-center max-w-3xl mx-auto mb-20">
                        <h2 className="text-4xl font-bold tracking-tighter">
                            Ihre Vorteile bei Nexus Bank
                        </h2>
                        <p className="mt-4 text-white/60">
                            Die perfekte Fusion aus Innovation und Finanzsicherheit.
                        </p>
                    </div>

                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <FeatureCard
                            icon={<Lock />}
                            title="Maximale Sicherheit"
                            description="Höchste Sicherheitsstandards und umfassende Einlagensicherung."
                        />
                        <FeatureCard
                            icon={<Wallet />}
                            title="Persönliche Beratung"
                            description="24/7 Expertenberatung - digital oder in unserer exklusiven Filiale."
                        />
                        <FeatureCard
                            icon={<CircleDollarSign />}
                            title="Innovative Finanzprodukte"
                            description="Von digitalen Konten bis zu hochmodernen Investmentlösungen."
                        />
                    </div>
                </div>
            </div>

            {/* Call to Action */}
            <div className="relative py-32">
                <div className="absolute inset-0 bg-gradient-to-t from-black to-purple-900/20"></div>

                <div className="relative max-w-3xl mx-auto text-center px-4">
                    <h2 className="text-4xl font-bold tracking-tighter mb-6">
                        Werden Sie Teil der Nexus Bank
                    </h2>
                    <p className="text-white/60 mb-8">
                        Die Nexus Bank bietet exklusive Finanzlösungen und individuellen Service für eine anspruchsvolle
                        Klientel. Mit innovativen Technologien und maßgeschneiderten Strategien sichern wir Ihre
                        finanzielle Zukunft – diskret, sicher und zukunftsorientiert.
                    </p>
                    <a
                        href="/login"
                        className="group relative inline-flex items-center px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden"
                    >
                        <span className="relative z-10 font-medium">Portal öffnen</span>
                        <div
                            className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                    </a>
                </div>
            </div>

            {/* Footer */}
            <footer className="border-t border-white/10 py-12">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid md:grid-cols-4 gap-8">
                        <div className="space-y-4">
                            <div className="flex items-center">
                                <Zap className="h-6 w-6 text-purple-500" />
                                <span className="ml-2 text-xl font-bold">Nexus Bank</span>
                            </div>
                            <p className="text-white/60">
                                Die Zukunft des Bankwesens.
                            </p>
                        </div>

                        <div>
                            <h4 className="font-medium mb-4">Produkte</h4>
                            <ul className="space-y-2 text-white/60">
                                <li><a href="/account" className="hover:text-purple-400 transition-colors">Digitales Konto</a></li>
                                <li><a href="/investment" className="hover:text-purple-400 transition-colors">Investments</a></li>
                                <li><a href="/finance" className="hover:text-purple-400 transition-colors">Immobilienfinanzierung</a></li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="font-medium mb-4">Unternehmen</h4>
                            <ul className="space-y-2 text-white/60">
                                <li><a href="/aboutus" className="hover:text-purple-400 transition-colors">Über uns</a></li>
                                <li><a href="/team" className="hover:text-purple-400 transition-colors">Unser Team</a></li>
                                <li><a href="/career" className="hover:text-purple-400 transition-colors">Karriere</a></li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="font-medium mb-4">Rechtliches</h4>
                            <ul className="space-y-2 text-white/60">
                                <li><a href="/privacy" className="hover:text-purple-400 transition-colors">Datenschutz</a></li>
                                <li><a href="/agb" className="hover:text-purple-400 transition-colors">AGB</a></li>
                                <li><a href="/impressum" className="hover:text-purple-400 transition-colors">Impressum</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="mt-12 pt-8 border-t border-white/10 text-center text-white/40">
                        <p>&copy; 2024 Nexus Bank. Finanzen neu gedacht.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

// Feature Card Component
const FeatureCard = ({ icon, title, description }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mb-6">
                    {React.cloneElement(icon, { className: "h-6 w-6 text-white" })}
                </div>
                <h3 className="text-xl font-medium mb-2">{title}</h3>
                <p className="text-white/60">{description}</p>
            </div>
        </div>
    );
};

// Navigation Link Component
const NavLink = ({ href, text }) => {
    return (
        <a
            href={href}
            className="relative group text-white/60 hover:text-white transition-colors duration-300"
        >
            {text}
            <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-gradient-to-r from-purple-500 to-pink-500 group-hover:w-full transition-all duration-300"></span>
        </a>
    );
};

export default NexusBankWebsite;
