import React from 'react';
import {
    Zap,
    LineChart,
    Shield,
    Boxes,
    Binary,
    Brain,
    BarChart3,
    Building2,
    Award,
    Lock
} from 'lucide-react';

// Add NavLink component
const NavLink = ({ href, text }) => (
    <a
        href={href}
        className="text-white/70 hover:text-white transition-colors duration-300"
    >
        {text}
    </a>
);

const Services = () => {
    return (
        <div className="min-h-screen bg-black text-white">
            {/* Futuristische Navigation */}
            <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between h-20">
                        <a href="/" className="flex items-center hover:opacity-80 transition-opacity duration-300">
                            <Zap className="h-8 w-8 text-purple-500" strokeWidth={2.5} />
                            <span className="ml-2 text-2xl font-bold tracking-tighter">Nexus Bank</span>
                        </a>

                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink href="/aboutus" text="Über uns"/>
                            <NavLink href="/services" text="Unsere Dienstleistungen"/>
                            <NavLink href="/card" text="Kreditkarten und Debitkarten Modelle" />
                            <NavLink href="/contact" text="Kontakt"/>
                            <a
                                href="/portfolio"
                                className="group relative px-6 py-2 overflow-hidden rounded-full bg-white/10 hover:bg-white/20 transition-all duration-300"
                            >
                                <span className="relative z-10">Open Portal</span>
                                <div className="absolute inset-0 blur-lg bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden pt-20">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>

                {/* Animated background elements */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10 mb-6">
                        <Award className="h-4 w-4 text-purple-400" />
                        <span className="text-sm text-purple-200">Innovative Finanzlösungen</span>
                    </div>

                    <h1 className="text-5xl lg:text-6xl font-bold tracking-tighter leading-tight mb-6">
                        Unsere
                        <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            {" "}Dienstleistungen
                        </span>
                    </h1>

                    <p className="text-xl text-white/60 max-w-3xl mx-auto">
                        Die Nexus Bank, als Teil der Nexus Dynamics Group, kombiniert traditionelles Banking
                        mit hochmodernen Technologien, um innovative und zukunftssichere Finanzlösungen anzubieten.
                    </p>
                </div>
            </div>

            {/* Services Grid */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <ServiceCard
                            icon={<LineChart className="h-6 w-6 text-white" />}
                            title="Investitionsstrategien"
                            description="Maßgeschneiderte Investmentpläne für Ihre finanzielle Zukunft."
                            features={[
                                "Langfristige Kapitalanlage",
                                "Startup-Finanzierung",
                                "Risikomanagement",
                                "Globale Investmentpartnerschaften",
                                "Quantencomputing für Finanzprognosen"
                            ]}
                        />
                        <ServiceCard
                            icon={<Binary className="h-6 w-6 text-white" />}
                            title="Finanztechnologie-Lösungen"
                            description="Modernste Technologien für optimierte Finanzprozesse."
                            features={[
                                "Blockchain-gestützte Transaktionen",
                                "Sichere, KI-unterstützte Überweisungen",
                                "Algorithmen für Big-Data-Analyse",
                                "Quantentechnologie für Echtzeitanalysen"
                            ]}
                        />
                        <ServiceCard
                            icon={<BarChart3 className="h-6 w-6 text-white" />}
                            title="Vermögensverwaltung"
                            description="Professionelle Verwaltung und Optimierung Ihres Vermögens."
                            features={[
                                "Portfolio-Optimierung",
                                "Finanzplanung und Beratung",
                                "Risikobasierte Anlagestrategien",
                                "Exklusive Verwaltung für hochkarätige Kunden"
                            ]}
                        />
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div className="relative py-20 bg-white/5">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold mb-4">Unsere Technologie-Vorteile</h2>
                        <p className="text-white/60 max-w-2xl mx-auto">
                            Die Nexus Bank und die Nexus Dynamics Group setzen auf die neueste Technologie,
                            um Ihre Sicherheit und den Zugang zu innovativen Finanzlösungen zu gewährleisten.
                        </p>
                    </div>

                    <div className="grid md:grid-cols-4 gap-8">
                        <FeatureCard
                            icon={<Shield className="h-6 w-6 text-white" />}
                            title="Maximale Sicherheit"
                            description="Modernste Verschlüsselungstechnologien und dedizierte Sicherheitslösungen."
                        />
                        <FeatureCard
                            icon={<Zap className="h-6 w-6 text-white" />}
                            title="Schnelle Prozesse"
                            description="Automatisierte Systeme für nahtlose und effiziente Finanztransaktionen."
                        />
                        <FeatureCard
                            icon={<Brain className="h-6 w-6 text-white" />}
                            title="KI-gestützte Analyse"
                            description="Intelligente Algorithmen für fundierte Investitionsentscheidungen und Risikomanagement."
                        />
                        <FeatureCard
                            icon={<Lock className="h-6 w-6 text-white" />}
                            title="Blockchain-Technologie"
                            description="Transparente und sichere Transaktionen, die auf der Blockchain basieren."
                        />
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="relative py-20">
                <div className="max-w-3xl mx-auto text-center px-4">
                    <h2 className="text-3xl font-bold mb-6">
                        Bereit für die Zukunft des Banking?
                    </h2>
                    <p className="text-white/60 mb-8">
                        Kontaktieren Sie uns für eine persönliche Beratung und entdecken Sie unsere
                        innovativen Finanzlösungen, die auf die spezifischen Bedürfnisse der Nexus Dynamics Group abgestimmt sind.
                    </p>
                    <button className="group relative px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden">
                        <span className="relative z-10 font-medium">Beratungsgespräch vereinbaren</span>
                        <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                    </button>
                </div>
            </div>
        </div>
    );
};

const ServiceCard = ({ icon, title, description, features }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mb-6">
                    {icon}
                </div>
                <h3 className="text-xl font-medium mb-2">{title}</h3>
                <p className="text-white/60 mb-6">{description}</p>
                <ul className="space-y-2">
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center space-x-2">
                            <div className="w-1.5 h-1.5 rounded-full bg-gradient-to-r from-purple-400 to-pink-400"></div>
                            <span className="text-white/80">{feature}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const FeatureCard = ({ icon, title, description }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-2xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-6 rounded-2xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="w-10 h-10 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mb-4">
                    {icon}
                </div>
                <h3 className="text-lg font-medium mb-2">{title}</h3>
                <p className="text-white/60 text-sm">{description}</p>
            </div>
        </div>
    );
};

export default Services;