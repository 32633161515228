import React from 'react';
import {
    Briefcase,
    Award,
    TrendingUp,
    Globe,
    Users,
    Rocket,
    Zap
} from 'lucide-react';

// NavLink Component
const NavLink = ({ href, text }) => (
    <a
        href={href}
        className="text-white/70 hover:text-white transition-colors duration-300"
    >
        {text}
    </a>
);

const CareersPage = () => {
    return (
        <div className="min-h-screen bg-black text-white">
            {/* Navigation */}
            <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between h-20">
                        <a href="/" className="flex items-center hover:opacity-80 transition-opacity duration-300">
                            <Zap className="h-8 w-8 text-purple-500" strokeWidth={2.5}/>
                            <span className="ml-2 text-2xl font-bold tracking-tighter">Nexus Bank</span>
                        </a>

                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink href="/aboutus" text="Über uns"/>
                            <NavLink href="/services" text="Unsere Dienstleistungen"/>
                            <NavLink href="/card" text="Kreditkarten und Debitkarten Modelle"/>
                            <NavLink href="/contact" text="Kontakt"/>
                            <a
                                href="/portfolio"
                                className="group relative px-6 py-2 overflow-hidden rounded-full bg-white/10 hover:bg-white/20 transition-all duration-300"
                            >
                                <span className="relative z-10">Open Portal</span>
                                <div className="absolute inset-0 blur-lg bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden pt-20">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>

                {/* Animated background elements */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10 mb-6">
                        <Briefcase className="h-4 w-4 text-purple-400" />
                        <span className="text-sm text-purple-200">Ihre Karriere bei uns</span>
                    </div>

                    <h1 className="text-5xl lg:text-6xl font-bold tracking-tighter leading-tight mb-6">
                        Karriere bei der
                        <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            {" "}Nexus Bank
                        </span>
                    </h1>

                    <p className="text-xl text-white/60 max-w-3xl mx-auto">
                        Werden Sie Teil unseres exklusiven Teams und gestalten Sie die Zukunft der Finanzwelt aktiv mit. Bei der Nexus Bank erwarten Sie spannende Aufgaben, herausragende Entwicklungschancen und ein inspirierendes Umfeld, das Innovation und Zusammenarbeit fördert.
                    </p>
                </div>
            </div>

            {/* Career Features Section */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <CareerFeature
                            icon={<Award className="h-6 w-6 text-white" />}
                            title="Exklusives Arbeitsumfeld"
                            description="Bei der Nexus Bank arbeiten Sie in einem einzigartigen Umfeld, das auf Exklusivität, Professionalität und höchste Standards ausgerichtet ist. Wir schaffen eine Atmosphäre, in der Sie sich weiterentwickeln können."
                        />
                        <CareerFeature
                            icon={<TrendingUp className="h-6 w-6 text-white" />}
                            title="Individuelle Wachstumschancen"
                            description="Wir fördern Ihre persönliche und berufliche Entwicklung durch maßgeschneiderte Karrierepfade und Fortbildungsprogramme. Entfalten Sie Ihr Potenzial mit uns."
                        />
                        <CareerFeature
                            icon={<Globe className="h-6 w-6 text-white" />}
                            title="Globale Perspektive"
                            description="Als Teil der Nexus Dynamics Group profitieren Sie von einem globalen Netzwerk und internationalen Projekten, die Ihnen eine weltweit vernetzte Karriere ermöglichen."
                        />
                        <CareerFeature
                            icon={<Users className="h-6 w-6 text-white" />}
                            title="Teamkultur und Zusammenarbeit"
                            description="Unsere Teamkultur basiert auf Respekt, Zusammenarbeit und Innovation. Wir glauben, dass wir nur gemeinsam die besten Ergebnisse erzielen können."
                        />
                        <CareerFeature
                            icon={<Rocket className="h-6 w-6 text-white" />}
                            title="Innovatives Arbeitsumfeld"
                            description="Wir setzen auf modernste Technologien und Prozesse, um innovative Lösungen zu entwickeln. Arbeiten Sie in einem Umfeld, das die Finanztechnologie von morgen gestaltet."
                        />
                        <CareerFeature
                            icon={<Briefcase className="h-6 w-6 text-white" />}
                            title="Vielfältige Karrieremöglichkeiten"
                            description="Ob Sie im Investment, in der Technologie oder in der Kundenberatung tätig sind – bei uns finden Sie eine Vielzahl von Karrieremöglichkeiten, die zu Ihren Interessen und Fähigkeiten passen."
                        />
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="relative py-20">
                <div className="max-w-3xl mx-auto text-center px-4">
                    <h2 className="text-3xl font-bold mb-6">
                        Entdecken Sie Ihre Möglichkeiten bei der Nexus Bank
                    </h2>
                    <p className="text-white/60 mb-8">
                        Starten Sie Ihre Karriere bei uns und gestalten Sie die Zukunft des Finanzwesens. Werden Sie Teil unseres Teams und erleben Sie, wie spannend und erfüllend eine Karriere bei der Nexus Bank sein kann.
                    </p>
                    <button className="group relative px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden">
                        <span className="relative z-10 font-medium">Jetzt bewerben</span>
                        <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Career Feature Card mit gleichem Design wie ValueCard
const CareerFeature = ({ icon, title, description }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mb-6">
                    {icon}
                </div>
                <h3 className="text-xl font-medium mb-2">{title}</h3>
                <p className="text-white/60">{description}</p>
            </div>
        </div>
    );
};

export default CareersPage;