import React from 'react';
import {
    Zap,
    Menu,
    X,
    Shield,
    Users,
    Globe,
    Award,
    Building,
    Rocket,
    Target
} from 'lucide-react';

// Add NavLink component
const NavLink = ({ href, text }) => (
    <a
        href={href}
        className="text-white/70 hover:text-white transition-colors duration-300"
    >
        {text}
    </a>
);

const AboutUs = () => {
    return (
        <div className="min-h-screen bg-black text-white">
            {/* Futuristische Navigation */}
            <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between h-20">
                        <a href="/" className="flex items-center hover:opacity-80 transition-opacity duration-300">
                            <Zap className="h-8 w-8 text-purple-500" strokeWidth={2.5}/>
                            <span className="ml-2 text-2xl font-bold tracking-tighter">Nexus Bank</span>
                        </a>

                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink href="/aboutus" text="Über uns"/>
                            <NavLink href="/services" text="Unsere Dienstleistungen"/>
                            <NavLink href="/card" text="Kreditkarten und Debitkarten Modelle"/>
                            <NavLink href="/contact" text="Kontakt"/>
                            <a
                                href="/portfolio"
                                className="group relative px-6 py-2 overflow-hidden rounded-full bg-white/10 hover:bg-white/20 transition-all duration-300"
                            >
                                <span className="relative z-10">Open Portal</span>
                                <div
                                    className="absolute inset-0 blur-lg bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden pt-20">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>

                {/* Animated background elements */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10 mb-6">
                        <Award className="h-4 w-4 text-purple-400" />
                        <span className="text-sm text-purple-200">Innovation trifft Investment</span>
                    </div>

                    <h1 className="text-5xl lg:text-6xl font-bold tracking-tighter leading-tight mb-6">
                        Eine Bank der
                        <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            {" "}nächsten Generation
                        </span>
                    </h1>

                    <p className="text-xl text-white/60 max-w-3xl mx-auto">
                        Als Teil der Nexus Dynamics Group verbinden wir Investmentexpertise mit technologischer Innovation.
                        Wir investieren in die Zukunft und gestalten sie aktiv mit.
                    </p>
                </div>
            </div>

            {/* Rest of the components remain the same */}
            {/* Kennzahlen Grid */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                        <StatsCard
                            number="2021"
                            label="Gegründet"
                            icon={<Building className="h-6 w-6 text-white" />}
                        />
                        <StatsCard
                            number="100+"
                            label="Startup Investments"
                            icon={<Rocket className="h-6 w-6 text-white" />}
                        />
                        <StatsCard
                            number="12"
                            label="Eigene Ventures"
                            icon={<Globe className="h-6 w-6 text-white" />}
                        />
                        <StatsCard
                            number="2.5 Mrd"
                            label="Investment Portfolio"
                            icon={<Target className="h-6 w-6 text-white" />}
                        />
                    </div>
                </div>
            </div>

            {/* Geschichte/Timeline Section */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-12">Unsere Geschichte</h2>
                    <div className="space-y-12">
                        <TimelineItem
                            year="2021"
                            title="Die Gründung"
                            description="Gründung der Nexus Bank als zukunftsorientierte Investment-Bank der Nexus Dynamics Group."
                        />
                        <TimelineItem
                            year="2022"
                            title="Erste Investments"
                            description="Erfolgreiche Investments in über 25 vielversprechende Startups aus dem Tech-Sektor."
                        />
                        <TimelineItem
                            year="2023"
                            title="Eigene Ventures"
                            description="Start der ersten eigenen Venture-Projekte in den Bereichen FinTech und KI."
                        />
                        <TimelineItem
                            year="2024"
                            title="Expansion"
                            description="Ausbau des Investment-Portfolios und Etablierung als führender Innovationstreiber im Finanzsektor."
                        />
                    </div>
                </div>
            </div>

            {/* Werte Section */}
            <div className="relative py-20 bg-white/5">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold mb-4">Unsere Werte</h2>
                        <p className="text-white/60 max-w-2xl mx-auto">
                            Diese Grundsätze leiten unser Handeln und definieren unsere Unternehmenskultur.
                        </p>
                    </div>

                    <div className="grid md:grid-cols-3 gap-8">
                        <ValueCard
                            icon={<Shield className="h-6 w-6 text-white" />}
                            title="Innovation & Disruption"
                            description="Wir identifizieren und fördern die Technologien und Unternehmen von morgen."
                        />
                        <ValueCard
                            icon={<Rocket className="h-6 w-6 text-white" />}
                            title="Venture Building"
                            description="Wir schaffen aktiv neue Unternehmen und entwickeln diese zu erfolgreichen Marktführern."
                        />
                        <ValueCard
                            icon={<Users className="h-6 w-6 text-white" />}
                            title="Technologische Expertise"
                            description="Unser Team vereint Finanz- und Tech-Expertise für maximalen Investmenterfolg."
                        />
                    </div>
                </div>
            </div>

            {/* Leadership Team */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-12">Unsere Führung</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <TeamMemberCard
                            name="Dr. Julia Weber"
                            position="CEO"
                            image="/api/placeholder/300/300"
                        />
                        <TeamMemberCard
                            name="Michael Schmidt"
                            position="CTO"
                            image="/api/placeholder/300/300"
                        />
                        <TeamMemberCard
                            name="Sarah Müller"
                            position="CFO"
                            image="/api/placeholder/300/300"
                        />
                    </div>
                </div>
            </div>

            {/* Call to Action */}
            <div className="relative py-20">
                <div className="max-w-3xl mx-auto text-center px-4">
                    <h2 className="text-3xl font-bold mb-6">
                        Werden Sie Teil der Innovation
                    </h2>
                    <p className="text-white/60 mb-8">
                        Investieren Sie mit uns in die Zukunft. Entdecken Sie vielversprechende Startups und neue Technologien.
                    </p>
                    <button className="group relative px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden">
                        <span className="relative z-10 font-medium">Investment Beratung anfragen</span>
                        <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Helper Components
const StatsCard = ({ number, label, icon }) => {
    return (
        <div className="bg-white/5 p-6 rounded-2xl text-center">
            <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mx-auto mb-4">
                {icon}
            </div>
            <div className="text-2xl font-bold mb-1">{number}</div>
            <div className="text-sm text-white/60">{label}</div>
        </div>
    );
};

const TimelineItem = ({ year, title, description }) => {
    return (
        <div className="relative flex items-start group">
            <div className="absolute top-0 left-0 w-0.5 h-full bg-white/10 group-last:hidden"></div>
            <div className="absolute top-0 left-0 w-4 h-4 -ml-1.5 rounded-full bg-gradient-to-r from-purple-500 to-pink-500"></div>
            <div className="ml-8">
                <div className="text-xl font-bold mb-2">{year}</div>
                <div className="font-medium mb-1">{title}</div>
                <div className="text-white/60">{description}</div>
            </div>
        </div>
    );
};

const ValueCard = ({ icon, title, description }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mb-6">
                    {icon}
                </div>
                <h3 className="text-xl font-medium mb-2">{title}</h3>
                <p className="text-white/60">{description}</p>
            </div>
        </div>
    );
};

const TeamMemberCard = ({ name, position, image }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-6 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <img
                    src={image}
                    alt={name}
                    className="w-full h-64 object-cover rounded-2xl mb-4"
                />
                <h3 className="text-xl font-medium mb-1">{name}</h3>
                <p className="text-white/60">{position}</p>
            </div>
        </div>
    );
};

export default AboutUs;