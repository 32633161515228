import React from 'react';

const PrivacyPolicyPage = () => {
    return (
        <div className="min-h-screen bg-black text-white px-6 lg:px-16 py-20">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8">Datenschutzerklärung</h1>
                <p className="text-white/60 mb-12">
                    Die Nexus Bank legt großen Wert auf den Schutz Ihrer persönlichen Daten. In dieser Datenschutzerklärung informieren wir Sie darüber, wie wir Ihre Daten erfassen, nutzen und schützen, um Ihnen die bestmögliche Sicherheit und Transparenz zu bieten.
                </p>

                <Section
                    title="1. Verantwortliche Stelle"
                    content={
                        <>
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist die Nexus Bank, vertreten durch den Datenschutzbeauftragten.
                            <br />
                            Gemäß <strong>Art. 4 Abs. 7 DSGVO</strong> ist die Nexus Bank für die Verarbeitung personenbezogener Daten auf dieser Website verantwortlich.
                            Bei Fragen zum Datenschutz oder zur Verarbeitung Ihrer Daten können Sie uns jederzeit unter <a href="mailto:privacy@nexusbank.com" className="text-purple-400">privacy@nexusbank.com</a> kontaktieren.
                        </>
                    }
                />

                <Section
                    title="2. Erhebung und Verarbeitung personenbezogener Daten"
                    content={
                        <>
                            Wir erfassen und verarbeiten personenbezogene Daten nur, wenn dies gesetzlich erlaubt ist oder Sie uns Ihre Einwilligung dazu gegeben haben. Zu den erhobenen Daten gehören Informationen wie Name, Kontaktdaten, Zahlungsinformationen und technische Daten, die bei der Nutzung unserer Website anfallen.
                            <br />
                            Die Rechtsgrundlage für die Verarbeitung Ihrer Daten ergibt sich aus <strong>Art. 6 Abs. 1 lit. a, b und f DSGVO</strong>.
                        </>
                    }
                />

                <Section
                    title="3. Verwendungszweck der Daten"
                    content={
                        <>
                            Ihre Daten werden ausschließlich zu den Zwecken verwendet, zu denen sie erhoben wurden. Dies umfasst die Verwaltung und Sicherung Ihrer Konten, die Abwicklung von Transaktionen, die Bereitstellung personalisierter Inhalte und die Optimierung unseres Angebots.
                            <br />
                            Diese Zwecke stehen in Einklang mit <strong>Art. 5 Abs. 1 lit. b DSGVO</strong>, der die Zweckbindung von personenbezogenen Daten regelt.
                        </>
                    }
                />

                <Section
                    title="4. Datenweitergabe an Dritte"
                    content={
                        <>
                            Die Nexus Bank gibt Ihre Daten nur dann an Dritte weiter, wenn dies zur Vertragserfüllung notwendig ist oder wir gesetzlich dazu verpflichtet sind. Eine Weitergabe erfolgt unter strikter Einhaltung der Datenschutzgesetze und nur an sorgfältig ausgewählte Dienstleister.
                            <br />
                            Die Übermittlung personenbezogener Daten an Dritte erfolgt gemäß <strong>Art. 28 DSGVO</strong>, der die Auftragsverarbeitung regelt, sowie <strong>Art. 6 Abs. 1 lit. c und f DSGVO</strong>.
                        </>
                    }
                />

                <Section
                    title="5. Einsatz von Cookies"
                    content={
                        <>
                            Unsere Website verwendet Cookies, um die Benutzerfreundlichkeit zu verbessern und unsere Dienstleistungen zu optimieren. Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden. Sie können die Verwendung von Cookies in den Einstellungen Ihres Browsers steuern und bei Bedarf deaktivieren.
                            <br />
                            Die Verwendung von Cookies basiert auf <strong>Art. 6 Abs. 1 lit. f DSGVO</strong>, da wir ein berechtigtes Interesse daran haben, Ihnen eine optimierte Benutzererfahrung zu bieten.
                        </>
                    }
                />

                <Section
                    title="6. Analyse-Tools und Tracking"
                    content={
                        <>
                            Zur Verbesserung unserer Dienstleistungen verwenden wir Analyse- und Tracking-Tools, die uns anonymisierte Daten zur Nutzung unserer Website liefern. Diese Daten helfen uns, unser Angebot zu verbessern und auf die Bedürfnisse unserer Kunden zuzuschneiden. Sie haben jederzeit die Möglichkeit, der Datenerhebung zu widersprechen.
                            <br />
                            Die Datenerhebung erfolgt gemäß <strong>Art. 6 Abs. 1 lit. f DSGVO</strong>, da die Optimierung unseres Angebots ein berechtigtes Interesse darstellt.
                        </>
                    }
                />

                <Section
                    title="7. Sicherheit Ihrer Daten"
                    content={
                        <>
                            Die Sicherheit Ihrer Daten ist uns ein großes Anliegen. Wir setzen umfassende Sicherheitsmaßnahmen wie Verschlüsselung und Zugriffsbeschränkungen ein, um Ihre Daten vor unbefugtem Zugriff und Missbrauch zu schützen.
                            <br />
                            Diese Maßnahmen entsprechen den Vorgaben in <strong>Art. 32 DSGVO</strong>, der die Sicherheit der Verarbeitung regelt.
                        </>
                    }
                />

                <Section
                    title="8. Ihre Rechte"
                    content={
                        <>
                            Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten zu erhalten. Außerdem haben Sie das Recht auf Berichtigung, Löschung und Einschränkung der Verarbeitung Ihrer Daten sowie das Recht auf Datenübertragbarkeit.
                            <br />
                            Diese Rechte ergeben sich aus <strong>Art. 15-20 DSGVO</strong>. Zur Ausübung Ihrer Rechte kontaktieren Sie uns bitte unter <a href="mailto:privacy@nexusbank.com" className="text-purple-400">privacy@nexusbank.com</a>.
                        </>
                    }
                />

                <Section
                    title="9. Änderungen dieser Datenschutzerklärung"
                    content={
                        <>
                            Wir behalten uns das Recht vor, diese Datenschutzerklärung gelegentlich anzupassen, um aktuellen rechtlichen Anforderungen gerecht zu werden. Die aktuelle Version ist jederzeit auf unserer Website verfügbar.
                            <br />
                            Dies entspricht <strong>Art. 13 Abs. 3 DSGVO</strong>, der verlangt, dass Nutzer über Änderungen der Verarbeitung informiert werden.
                        </>
                    }
                />

                <div className="mt-16">
                    <h2 className="text-xl font-bold mb-4">Kontakt</h2>
                    <p className="text-white/80">
                        Wenn Sie Fragen oder Anmerkungen zum Datenschutz haben, wenden Sie sich bitte an unseren Datenschutzbeauftragten unter:
                        <br />
                        <span className="font-semibold">Email:</span> <a href="mailto:privacy@nexusbank.com" className="text-purple-400">privacy@nexusbank.com</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, content }) => {
    return (
        <div className="mb-10">
            <h2 className="text-2xl font-semibold mb-2">{title}</h2>
            <p className="text-white/80">{content}</p>
        </div>
    );
};

export default PrivacyPolicyPage;
