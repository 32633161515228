import React, { useState } from 'react';
import {
    LineChart,
    BarChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import {
    Wallet, TrendingUp, Shield, Bell, Settings, ArrowUpRight,
    ArrowDownRight, Activity, PieChart, Binary, Briefcase,
    Award, ChartBar, Eye, Zap, BadgeDollarSign, Target,
    Bookmark, AlertTriangle
} from 'lucide-react';

// Demo-Daten
const portfolioData = [
    { name: 'Jan', wert: 45000 },
    { name: 'Feb', wert: 47200 },
    { name: 'Mär', wert: 46800 },
    { name: 'Apr', wert: 48900 },
    { name: 'Mai', wert: 51200 },
    { name: 'Jun', wert: 53400 }
];

const aktienPositionen = [
    { name: 'Tesla', symbol: 'TSLA', kurs: 750, change: '+3.5%' },
    { name: 'Apple', symbol: 'AAPL', kurs: 175.5, change: '-0.8%' },
    { name: 'Microsoft', symbol: 'MSFT', kurs: 299.0, change: '+1.1%' }
];

const marktTrends = [
    { name: 'Dow Jones', wert: 34000, trend: 'up', change: '+0.5%' },
    { name: 'NASDAQ', wert: 13800, trend: 'down', change: '-0.3%' },
    { name: 'S&P 500', wert: 4350, trend: 'up', change: '+0.2%' }
];

const DemoPortal = () => {
    const [activeTab, setActiveTab] = useState('dashboard');

    return (
        <div className="min-h-screen bg-black text-white">
            {/* Hero Banner */}
            <div className="relative py-8 overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4">
                    <div className="flex items-center justify-between mb-8">
                        <div>
                            <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10 mb-4">
                                <Award className="h-4 w-4 text-purple-400" />
                                <span className="text-sm text-purple-200">Demo Portal</span>
                            </div>
                            <h1 className="text-4xl font-bold mb-2">Willkommen im Nexus Portal</h1>
                            <p className="text-white/60">Entdecken Sie die Zukunft des digitalen Banking</p>
                        </div>
                        <div className="flex space-x-4">
                            <button className="p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-colors">
                                <Bell className="h-5 w-5" />
                            </button>
                            <button className="p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-colors">
                                <Settings className="h-5 w-5" />
                            </button>
                        </div>
                    </div>

                    {/* Quick Stats */}
                    <div className="grid grid-cols-4 gap-6 mb-8">
                        <QuickStat
                            title="Portfolio Wert"
                            value="€53,400"
                            change="+5.2%"
                            trend="up"
                            icon={<Briefcase className="h-5 w-5" />}
                        />
                        <QuickStat
                            title="Rendite (YTD)"
                            value="18.7%"
                            change="+2.3%"
                            trend="up"
                            icon={<TrendingUp className="h-5 w-5" />}
                        />
                        <QuickStat
                            title="Investitionen"
                            value="12"
                            change="+1"
                            trend="up"
                            icon={<PieChart className="h-5 w-5" />}
                        />
                        <QuickStat
                            title="Risiko-Score"
                            value="B+"
                            change="Stabil"
                            trend="neutral"
                            icon={<Shield className="h-5 w-5" />}
                        />
                    </div>

                    {/* Main Content */}
                    <div className="grid grid-cols-3 gap-6">
                        {/* Portfolio Chart */}
                        <div className="col-span-2 relative">
                            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-3xl blur opacity-25"></div>
                            <div className="relative bg-black/40 backdrop-blur-xl p-6 rounded-3xl border border-white/10">
                                <h2 className="text-xl font-bold mb-4">Portfolio Entwicklung</h2>
                                <div className="h-64">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart data={portfolioData}>
                                            <CartesianGrid strokeDasharray="3 3" stroke="#ffffff10" />
                                            <XAxis dataKey="name" stroke="#ffffff60" />
                                            <YAxis stroke="#ffffff60" />
                                            <Tooltip
                                                contentStyle={{
                                                    backgroundColor: '#000000',
                                                    border: '1px solid rgba(255,255,255,0.1)',
                                                    borderRadius: '0.5rem'
                                                }}
                                            />
                                            <Line
                                                type="monotone"
                                                dataKey="wert"
                                                stroke="#8b5cf6"
                                                strokeWidth={2}
                                                dot={false}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>

                        {/* Aktien Positionen */}
                        <div className="relative bg-black/40 backdrop-blur-xl p-6 rounded-3xl border border-white/10 h-full">
                            <h2 className="text-xl font-bold mb-4">Aktien Positionen</h2>
                            <div className="space-y-4">
                                {aktienPositionen.map((aktie, index) => (
                                    <div key={index} className="flex justify-between items-center bg-white/5 p-4 rounded-xl">
                                        <div>
                                            <div className="text-lg font-semibold">{aktie.name}</div>
                                            <div className="text-sm text-white/60">{aktie.symbol}</div>
                                        </div>
                                        <div className="text-right">
                                            <div className="text-lg">{aktie.kurs}€</div>
                                            <div className={aktie.change.startsWith('+') ? 'text-green-400' : 'text-red-400'}>
                                                {aktie.change}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Markttrends */}
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold mb-4">Markttrends</h2>
                        <div className="grid grid-cols-3 gap-6">
                            {marktTrends.map((trend, index) => (
                                <div key={index} className="relative bg-black/40 backdrop-blur-xl p-4 rounded-3xl border border-white/10">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div className="text-lg font-semibold">{trend.name}</div>
                                            <div className="text-sm text-white/60">Index Wert: {trend.wert}</div>
                                        </div>
                                        <div className={`text-lg font-semibold ${trend.trend === 'up' ? 'text-green-400' : 'text-red-400'}`}>
                                            {trend.change}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Historische Rendite */}
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold mb-4">Historische Rendite</h2>
                        <div className="bg-black/40 backdrop-blur-xl p-6 rounded-3xl border border-white/10">
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={portfolioData}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#ffffff10" />
                                    <XAxis dataKey="name" stroke="#ffffff60" />
                                    <YAxis stroke="#ffffff60" />
                                    <Tooltip
                                        contentStyle={{
                                            backgroundColor: '#000000',
                                            border: '1px solid rgba(255,255,255,0.1)',
                                            borderRadius: '0.5rem'
                                        }}
                                    />
                                    <Bar dataKey="wert" fill="#8b5cf6" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Schnellstatistik Komponente
const QuickStat = ({ title, value, change, trend, icon }) => {
    return (
        <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-2xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-4 rounded-2xl border border-white/10">
                <div className="flex items-start justify-between mb-2">
                    <span className="text-white/60">{title}</span>
                    <div className="p-2 rounded-lg bg-white/5">
                        {icon}
                    </div>
                </div>
                <div className="text-2xl font-bold mb-1">{value}</div>
                <div className={`flex items-center space-x-1 text-sm ${
                    trend === 'up' ? 'text-green-400' : trend === 'down' ? 'text-red-400' : 'text-white/60'
                }`}>
                    {trend === 'up' ? <ArrowUpRight className="h-4 w-4" /> : <ArrowDownRight className="h-4 w-4" />}
                    <span>{change}</span>
                </div>
            </div>
        </div>
    );
};

export default DemoPortal;

