import React, { useState } from 'react';
import { Zap, Lock, User, Mail, ArrowRight, CreditCard, Briefcase, Building, FileCheck } from 'lucide-react';

const NavLink = ({ href, text }) => (
    <a href={href} className="text-white/70 hover:text-white transition-colors duration-300">
        {text}
    </a>
);

const RegistrationPortal = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        address: '',
        city: '',
        postalCode: '',
        phone: '',
        occupation: '',
        annualIncome: '',
        initialDeposit: '',
        idNumber: '',
        taxId: '',
        employerName: '',
        employmentDuration: '',
        investmentExperience: 'none',
        riskTolerance: 'low',
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateGermanIdNumber = (idNumber) => {
        // Überprüft das Format: L0GG000000
        // L = Buchstabe
        // 0 = beliebige Ziffer
        // GG = Zwei Ziffern für die ausstellende Behörde
        const idPattern = /^[A-Z][0-9][0-9]{2}[0-9]{6}$/;
        return idPattern.test(idNumber);
    };

    const validateMonthlyIncome = (annualIncome) => {
        const monthlyIncome = parseInt(annualIncome) / 12;
        return monthlyIncome >= 10000;
    };

    const [validationErrors, setValidationErrors] = useState({
        idNumber: '',
        annualIncome: ''
    });

    const validateStep = () => {
        const errors = { ...validationErrors };
        let isValid = true;

        switch(step) {
            case 1:
                isValid = formData.email && formData.password && formData.firstName && formData.lastName;
                break;
            case 2:
                const isIdValid = validateGermanIdNumber(formData.idNumber);
                if (!isIdValid) {
                    errors.idNumber = 'Ungültige Personalausweisnummer. Format: L0GG000000';
                    isValid = false;
                } else {
                    errors.idNumber = '';
                }
                isValid = isValid && formData.dateOfBirth && formData.address && formData.phone;
                break;
            case 3:
                const isIncomeValid = validateMonthlyIncome(formData.annualIncome);
                if (!isIncomeValid) {
                    errors.annualIncome = 'Das monatliche Einkommen muss mindestens 10.000€ betragen';
                    isValid = false;
                } else {
                    errors.annualIncome = '';
                }
                isValid = isValid && formData.occupation && formData.employerName;
                break;
            case 4:
                isValid = formData.initialDeposit >= 50000 && formData.investmentExperience;
                break;
            default:
                isValid = true;
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateStep()) {
            if (step < 4) {
                setStep(step + 1);
            } else {
                // Submit logic here
                console.log('Form submitted:', formData);
            }
        }
    };

    return (
        <div className="min-h-screen bg-black text-white">
            {/* Navigation */}
            <nav className="fixed w-full z-50 bg-black/50 backdrop-blur-xl border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between h-20">
                        <a href="/" className="flex items-center hover:opacity-80 transition-opacity duration-300">
                            <Zap className="h-8 w-8 text-purple-500" strokeWidth={2.5} />
                            <span className="ml-2 text-2xl font-bold tracking-tighter">Nexus Bank</span>
                        </a>

                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink href="/aboutus" text="Über uns" />
                            <NavLink href="/services" text="Dienstleistungen" />
                            <NavLink href="/contact" text="Kontakt" />
                        </div>
                    </div>
                </div>
            </nav>

            {/* Registration Form */}
            <div className="relative min-h-screen flex items-center justify-center pt-20 px-4">
                {/* Background Effects */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/20 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                {/* Form Container */}
                <div className="relative w-full max-w-md">
                    <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-2xl blur opacity-25"></div>
                    <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-2xl border border-white/10">
                        {/* Progress Indicator */}
                        <div className="flex justify-center mb-8 space-x-2">
                            {[1, 2, 3, 4].map((index) => (
                                <div
                                    key={index}
                                    className={`w-2 h-2 rounded-full transition-colors duration-300 ${
                                        step >= index ? 'bg-purple-500' : 'bg-white/20'
                                    }`}
                                ></div>
                            ))}
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            {step === 1 && (
                                <>
                                    <h2 className="text-2xl font-bold text-center mb-6">
                                        Willkommen bei <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">Nexus Bank</span>
                                    </h2>
                                    <div className="space-y-4">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="E-Mail"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Passwort"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="firstName"
                                            placeholder="Vorname"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="lastName"
                                            placeholder="Nachname"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </>
                            )}

                            {step === 2 && (
                                <>
                                    <h2 className="text-2xl font-bold text-center mb-6">Persönliche Details</h2>
                                    <div className="space-y-4">
                                        <input
                                            type="date"
                                            name="dateOfBirth"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white"
                                            value={formData.dateOfBirth}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="address"
                                            placeholder="Adresse"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                            value={formData.address}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="phone"
                                            placeholder="Telefonnummer"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div>
                                            <input
                                                type="text"
                                                name="idNumber"
                                                placeholder="Personalausweisnummer (Format: L0GG000000)"
                                                className={`w-full bg-white/5 border ${validationErrors.idNumber ? 'border-red-500' : 'border-white/10'} rounded-lg px-4 py-2.5 text-white placeholder:text-white/40`}
                                                value={formData.idNumber}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {validationErrors.idNumber && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {validationErrors.idNumber}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            {step === 3 && (
                                <>
                                    <h2 className="text-2xl font-bold text-center mb-6">Berufliche Details</h2>
                                    <div className="space-y-4">
                                        <input
                                            type="text"
                                            name="occupation"
                                            placeholder="Beruf"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                            value={formData.occupation}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div>
                                            <input
                                                type="number"
                                                name="annualIncome"
                                                placeholder="Jahreseinkommen (min. 120.000€)"
                                                className={`w-full bg-white/5 border ${validationErrors.annualIncome ? 'border-red-500' : 'border-white/10'} rounded-lg px-4 py-2.5 text-white placeholder:text-white/40`}
                                                value={formData.annualIncome}
                                                onChange={handleInputChange}
                                                min="120000"
                                                required
                                            />
                                            {validationErrors.annualIncome && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {validationErrors.annualIncome}
                                                </p>
                                            )}
                                            <p className="mt-1 text-sm text-white/60">
                                                Mindestens 10.000€ monatliches Einkommen erforderlich
                                            </p>
                                        </div>
                                        <input
                                            type="text"
                                            name="employerName"
                                            placeholder="Arbeitgeber"
                                            className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                            value={formData.employerName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </>
                            )}

                            {step === 4 && (
                                <>
                                    <h2 className="text-2xl font-bold text-center mb-6">Investment Profil</h2>
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-white/60 mb-2">Mindesteinlage (€50,000 Minimum)</label>
                                            <input
                                                type="number"
                                                name="initialDeposit"
                                                placeholder="Initial Deposit"
                                                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white placeholder:text-white/40"
                                                value={formData.initialDeposit}
                                                onChange={handleInputChange}
                                                min="50000"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-white/60 mb-2">Investment Erfahrung</label>
                                            <select
                                                name="investmentExperience"
                                                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white"
                                                value={formData.investmentExperience}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="none">Keine Erfahrung</option>
                                                <option value="beginner">Anfänger (1-3 Jahre)</option>
                                                <option value="intermediate">Fortgeschritten (3-7 Jahre)</option>
                                                <option value="expert">Experte (7+ Jahre)</option>
                                            </select>
                                        </div>
                                    </div>
                                </>
                            )}

                            <button
                                type="submit"
                                className="group relative w-full px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg overflow-hidden"
                            >
                                <span className="relative z-10 flex items-center justify-center font-medium">
                                    {step === 4 ? 'Registrierung abschließen' : 'Weiter'}
                                    <ArrowRight className="ml-2 h-4 w-4" />
                                </span>
                                <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                            </button>

                            {step === 1 && (
                                <p className="text-center mt-4 text-sm text-white/60">
                                    Bereits Kunde? <a href="/login" className="text-purple-400 hover:text-purple-300">Anmelden</a>
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPortal;