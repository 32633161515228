import React from 'react';
import {
    Fingerprint,
    Cpu,
    Shield,
    Wallet,
    CircuitBoard,
    Gem,
    Sparkles,
    Key,
    Lock
} from 'lucide-react';

const PaymentSolutions = () => {
    return (
        <div className="min-h-screen bg-black text-white">
            {/* Hero Section */}
            <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden pt-20">
                <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black"></div>

                {/* Animated background */}
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-pink-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
                </div>

                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className="inline-flex items-center space-x-2 bg-white/5 px-4 py-2 rounded-full border border-white/10 mb-6">
                        <Cpu className="h-4 w-4 text-purple-400" />
                        <span className="text-sm text-purple-200">Revolutionäre Zahlungstechnologie</span>
                    </div>

                    <h1 className="text-5xl lg:text-6xl font-bold tracking-tighter leading-tight mb-6">
                        Nexus
                        <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            {" "}QuantumPay
                        </span>
                    </h1>

                    <p className="text-xl text-white/60 max-w-3xl mx-auto">
                        Die nächste Generation des Bezahlens: Quantenverschlüsselte Biometrie
                        ersetzt physische Karten für maximale Sicherheit und Komfort.
                    </p>
                </div>
            </div>

            {/* Payment Solutions Grid */}
            <div className="relative py-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <PlanCard
                            title="QuantumPay Essential"
                            icon={<CircuitBoard className="h-6 w-6 text-white" />}
                            price="0"
                            description="Die moderne Basis-Zahlungslösung für den digitalen Alltag."
                            features={[
                                "Biometrische Authentifizierung",
                                "Quantenverschlüsselte Transaktionen",
                                "Virtuelle Einmal-Tokens",
                                "Limit bis 10.000€/Monat",
                                "Grundlegende Versicherungen"
                            ]}
                            gradient="from-purple-600 to-blue-600"
                        />
                        <PlanCard
                            title="QuantumPay Infinite"
                            icon={<Gem className="h-6 w-6 text-white" />}
                            price="50"
                            description="Premium-Zahlungslösung für höchste Ansprüche."
                            features={[
                                "Multi-Biometrie-Authentifizierung",
                                "Unbegrenzte Transaktionen",
                                "KI-gestützte Fraud Prevention",
                                "Priority-Support 24/7",
                                "Exklusive Concierge-Services",
                                "Premium-Reiseversicherung"
                            ]}
                            gradient="from-purple-600 to-pink-600"
                            featured={true}
                        />
                        <PlanCard
                            title="QuantumPay Corporate"
                            icon={<Shield className="h-6 w-6 text-white" />}
                            price="200"
                            description="Maßgeschneiderte Lösung für Unternehmen."
                            features={[
                                "Unternehmensweite Biometrie-Integration",
                                "Eigene Quantum-Encryption-Keys",
                                "Unbegrenzte Sub-Accounts",
                                "Real-time Expense Management",
                                "API-Zugang",
                                "Dedizierter Account Manager"
                            ]}
                            gradient="from-pink-600 to-orange-600"
                        />
                    </div>
                </div>
            </div>

            {/* Features */}
            <div className="relative py-20 bg-white/5">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold mb-4">Revolutionäre Technologie</h2>
                        <p className="text-white/60 max-w-2xl mx-auto">
                            QuantumPay vereint modernste Technologien für ein sicheres und
                            nahtloses Zahlungserlebnis ohne physische Karten.
                        </p>
                    </div>

                    <div className="grid md:grid-cols-3 gap-8">
                        <FeatureCard
                            icon={<Fingerprint className="h-6 w-6 text-white" />}
                            title="Biometrische Authentifizierung"
                            description="Mehrfach-Biometrie mit Fingerabdruck, Iris-Scan und Verhaltensanalyse."
                        />
                        <FeatureCard
                            icon={<Key className="h-6 w-6 text-white" />}
                            title="Quantum-Encryption"
                            description="Quantenverschlüsselung macht jede Transaktion absolut sicher."
                        />
                        <FeatureCard
                            icon={<Sparkles className="h-6 w-6 text-white" />}
                            title="KI-Powered Security"
                            description="Echtzeit-Fraud-Detection durch künstliche Intelligenz."
                        />
                    </div>
                </div>
            </div>

            {/* CTA */}
            <div className="relative py-20">
                <div className="max-w-3xl mx-auto text-center px-4">
                    <h2 className="text-3xl font-bold mb-6">
                        Bereit für die Zukunft des Bezahlens?
                    </h2>
                    <p className="text-white/60 mb-8">
                        Werden Sie Teil der Payment-Revolution und erleben Sie
                        maximale Sicherheit kombiniert mit höchstem Komfort.
                    </p>
                    <button className="group relative px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full overflow-hidden">
                        <span className="relative z-10 font-medium">Jetzt QuantumPay aktivieren</span>
                        <div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                    </button>
                </div>
            </div>
        </div>
    );
};

const PlanCard = ({ icon, title, price, description, features, gradient, featured = false }) => {
    return (
        <div className={`group relative ${featured ? 'scale-105' : ''}`}>
            <div className={`absolute -inset-0.5 bg-gradient-to-r ${gradient} rounded-3xl blur opacity-0 group-hover:opacity-50 transition duration-500`}></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mb-6">
                    {icon}
                </div>
                <h3 className="text-xl font-medium mb-2">{title}</h3>
                <div className="mb-4">
                    <span className="text-3xl font-bold">{price}€</span>
                    <span className="text-white/60">/Monat</span>
                </div>
                <p className="text-white/60 mb-6">{description}</p>
                <ul className="space-y-3">
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center space-x-2">
                            <div className="w-1.5 h-1.5 rounded-full bg-gradient-to-r from-purple-400 to-pink-400"></div>
                            <span className="text-white/80">{feature}</span>
                        </li>
                    ))}
                </ul>
                {featured && (
                    <div className="absolute top-4 right-4">
                        <span className="px-3 py-1 text-xs font-medium bg-gradient-to-r from-purple-400 to-pink-400 rounded-full">
                            Empfohlen
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

const FeatureCard = ({ icon, title, description }) => {
    return (
        <div className="group relative">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-2xl blur opacity-0 group-hover:opacity-50 transition duration-500"></div>
            <div className="relative bg-black/40 backdrop-blur-xl p-6 rounded-2xl border border-white/10 hover:border-white/20 transition-colors duration-300">
                <div className="w-10 h-10 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mb-4">
                    {icon}
                </div>
                <h3 className="text-lg font-medium mb-2">{title}</h3>
                <p className="text-white/60 text-sm">{description}</p>
            </div>
        </div>
    );
};

export default PaymentSolutions;